<template>
  <a-upload
    name="file"
    :multiple="false"
    :action="`https://api.viajar.tur.br/v1/media/upload-to-s3?module=documentations-paragraphs&id=${documentationId}&sub-folder=`"
    :headers="{
      authorization: `Bearer ${this.$store.state.token}`,
    }"
    @change="uploadedParagraphPicture"
    class="paragraph-picture"
  >
    <a-icon
      v-if="[undefined, ''].includes(paragraphPicture)"
      class="ico"
      type="picture"
    />
    <img v-if="paragraphPicture" :src="paragraphPicture" alt="picture" />
  </a-upload>
</template>

<script>
export default {
  name: "DocumentationImageParagraphs",
  props: {
    paragraph: Object,
    documentationId: String,
  },
  components: {},
  data() {
    return {
      paragraphPicture: "",
    };
  },
  mounted() {
    this.paragraphPicture = this.paragraph.content;
  },
  methods: {
    uploadedParagraphPicture(response) {
      if (response.file.response.url != undefined) {
        this.paragraph.content = response.file.response.url;
        this.paragraphPicture = response.file.response.url;

        this.$emit("startsLoading");

        this.$http.post("/documentation-paragraph/update", {
          id: this.paragraph.id,
          user_id: this.$store.state.userData.id,
          documentation_id: this.documentationId,
          type: this.paragraph.type,
          content: response.file.response.url,
          order: this.paragraph.order,
        }).then(() => {
          this.$emit("savedSuccessfully");
        });
      }
    },
  },
};
</script>

<style lang="sass">
.paragraph-picture
  .ant-upload.ant-upload-select
    display: block
</style>

<style lang="sass" scoped>
.paragraph-picture
  text-align: center
  img
    max-width: 100%
  .ico
    font-size: 224px
    color: #eee
    cursor: pointer
</style>
