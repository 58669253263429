<template>
  <section class="pd-0">
    <aForm @submit.prevent="onSubmitForm" :form="form">
      <aRow>
        <aCol class="top-bar" :span="24">
          <aRow type="flex" justify="space-between">
            <aCol>
              <aRow type="flex" justify="start" :gutter="20">
                <aCol>
                  <a-icon
                    class="menu"
                    type="menu"
                    @click="openDocumentInfosDrawer = true"
                  />
                </aCol>

                <aCol>
                  <a-form-item class="model-name">
                    <a-input
                      v-decorator="[
                        'title',
                        {
                          rules: [
                            {
                              required: true,
                              message: 'Obrigatório',
                            },
                          ],
                        },
                      ]"
                      placeholder="Escreva..."
                    >
                    </a-input>
                  </a-form-item>
                </aCol>

                <aCol>
                  <a-tooltip placement="right" title="Ver">
                    <a
                      class="view"
                      @click="
                        $router.push(
                          `/documentations/view/${documentation.details.id}`
                        )
                      "
                    >
                      <a-icon type="eye" />
                    </a>
                  </a-tooltip>
                </aCol>
              </aRow>
            </aCol>
            <aCol class="">
              <a-button
                html-type="submit"
                :loading="loadingDocumentationForm"
                size="large"
                type="primary"
                >Atualizar</a-button
              >
            </aCol>
          </aRow>
        </aCol>
      </aRow>

      <a-drawer
        title="Dados da documentação"
        placement="left"
        width="600px"
        :visible="openDocumentInfosDrawer"
        @close="openDocumentInfosDrawer = false"
      >
        <DocumentationExtraDataDrawer
          v-if="openDocumentInfosDrawer"
          :form="form"
          :tempDocumentation="tempDocumentation"
        />
      </a-drawer>
    </aForm>

    <aRow>
      <aCol :span="24">
        <div class="paragraphs-wrapper">
          <DocumentationParagraphsSection
            :paragraphs="documentationParagraph.list"
            :documentationId="documentation.details.id"
            @onClickDeleteParagraph="getDocumentationParagraphs()"
            @startsLoading="loadingDocumentationForm = true"
            @afterUpdateAll="loadingDocumentationForm = false"
          />
        </div>
      </aCol>

      <aCol class="add-paragraph" :span="24">
        <aPopover v-model="openParagraphTypes" :title="false" trigger="click">
          <template #content>
            <ul class="paragraphs-list">
              <li
                v-for="({ name, value, ico }, i) in paragraphTypes"
                :key="i"
                @click="onClickAddParagraph(value)"
              >
                <a-icon class="ico" :type="ico" /> {{ name }}
              </li>
            </ul>
          </template>
          <a-button>Adicionar Parágrafo</a-button>
        </aPopover>
      </aCol>
    </aRow>

    <div v-if="loadingDocumentationForm" class="floating-loading">
      <a-icon type="loading" /> salvando...
    </div>
  </section>
</template>

<script>
import documentationsMixins from "@/components/documentations/mixins/documentationsMixins";
import documentationParagraphMixins from "@/components/documentations/mixins/documentationParagraphMixins";
import DocumentationParagraphsSection from "@/components/documentations/sections/DocumentationParagraphsSection.vue";
import DocumentationExtraDataDrawer from "@/components/documentations/drawers/DocumentationExtraDataDrawer.vue";

export default {
  name: "DocumentationPage",
  components: { DocumentationParagraphsSection, DocumentationExtraDataDrawer },
  mixins: [documentationsMixins, documentationParagraphMixins],
  data() {
    return {
      form: this.$form.createForm(this, {
        onValuesChange: this.updateTempDocumentation,
      }),
      loadingDocumentationForm: false,
      openParagraphTypes: false,
      openDocumentInfosDrawer: false,
      paragraphTypes: [
        {
          name: "Texto",
          value: "text",
          ico: "font-size",
        },
        {
          name: "Texto (Info)",
          value: "info",
          ico: "info-circle",
        },
        {
          name: "Texto (Sucesso)",
          value: "success",
          ico: "check-circle",
        },
        {
          name: "Texto (Atenção)",
          value: "warning",
          ico: "warning",
        },
        {
          name: "Texto (Erro)",
          value: "error",
          ico: "close-circle",
        },
        // {
        //   name: "Código",
        //   value: "code",
        //   ico: "code",
        // },
        {
          name: "Javascript",
          value: "text/javascript",
          ico: "code",
        },
        {
          name: "PHP",
          value: "text/php",
          ico: "code",
        },
        {
          name: "HTML",
          value: "text/html",
          ico: "code",
        },
        {
          name: "CSS",
          value: "text/css",
          ico: "code",
        },
        {
          name: "Imagem",
          value: "image",
          ico: "picture",
        },
        {
          name: "Arquivo",
          value: "file",
          ico: "file-zip",
        },
      ],
    };
  },
  computed: {
    routeTab() {
      return {
        title: `Documentação ${this.$route.params.id ?? ""}`,
        tips: "",
      };
    },
  },
  mounted() {
    this.getDocumentationById(this.$route.params.id).then((data) => {
      this.form.setFieldsValue(data.data);
      document.title = `${data.data.id} - ${data.data.title}`;
      this.documentationParagraph.filters.documentationId = data.data.id;
      this.documentationParagraph.filters.order = "ascend";
      this.documentationParagraph.filters.orderBy = "order";
      this.getDocumentationParagraphs();
    });
  },
  methods: {
    updateTempDocumentation(props, fields) {
      let field = Object.keys(fields)[0],
        value = Object.values(fields)[0];

      this.tempDocumentation[field] = value;

      if (Array.isArray(value)) {
        this.tempDocumentation[field] = JSON.stringify(value);
      }

      this.tempDocumentation = { ...this.tempDocumentation };
    },
    onClickAddParagraph(type) {
      this.$http
        .post("/documentation-paragraph/create", {
          user_id: this.$store.state.userData.id,
          documentation_id: this.documentation.details.id,
          type,
          order: this.documentationParagraph.list.length + 1,
        })
        .then(({ data }) => {
          this.$http.post("/log/create", {
            user_id: this.$store.state.userData.id,
            module_id: data.id,
            module: "documentation-paragraph",
            action: "create",
            description: `${this.$store.state.userData.first_name} ${this.$store.state.userData.last_name} cadastrou um parágrafo do tipo <b>${type}</b> na documentação ID ${this.$store.state.userData.id}.`,
          });

          setTimeout(() => {
            this.getDocumentationParagraphs();
          }, 500);
        })
        .catch(({ response }) => {
          this.$message.error(response.data.message);
        });
    },
    onSubmitForm(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        values.user_id = this.$store.state.userData.id;
        console.log(err, values);
        if (!err) {
          this.loadingDocumentationForm = true;
          this.$http
            .post("/documentation/update", this.tempDocumentation)
            .then(({ data }) => {
              this.$message.success(data.message);
              this.$http.post("/log/create", {
                user_id: this.$store.state.userData.id,
                module_id: data.id,
                module: "documentation",
                action: "update",
                description: `${this.$store.state.userData.first_name} ${this.$store.state.userData.last_name} atualizou a documentação ID ${this.tempDocumentation.id}.`,
              });
              this.loadingDocumentationForm = false;
            })
            .catch(({ response }) => {
              this.$message.error(response.data.message);
              this.loadingDocumentationForm = false;
            });
        }
      });
    },
  },
};
</script>

<style lang="sass" scoped>
.floating-loading
  position: fixed
  right: 20px
  bottom: 20px
.paragraphs-wrapper
  margin: 0 auto
  width: 800px
  margin-top: 40px
.add-paragraph
  position: relative
  z-index: 10
  text-align: center
  padding: 10px 10px 120px
  transition: .3s
.paragraphs-list
  list-style: none
  padding: 0
  margin:  -5px -10px
  li
    width: 200px
    background: #fff
    padding: 5px 10px 5px 0
    border-radius: 6px
    margin-bottom: 5px
    cursor: pointer
    transaction: .3s
    font-size: 13px
    color: #333
    font-weight:500
    .ico
      width: 60px
      width: 30px
      color: #aaa
  li:last-child
    margin: 0
  li:hover
    background: #fafafa
.top-bar
  position: sticky
  top: 0
  z-index: 10
  box-shadow: 0 0 40px #ddd
  padding: 10px
  background: #fff
  .view
    font-size: 24px
    top: 3px
    position: relative
  .menu
    position: relative
    top: 6px
    font-size: 28px
    color: #bbb
    margin-left: 10px
  .model-name
    margin: 0
    input
      border: 0
      width: 600px
      background: #eee
</style>
