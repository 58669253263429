var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('aCol',{attrs:{"span":24}},[_c('a-form-item',{staticClass:"travel-input-outer"},[_c('label',{staticClass:"filled"},[_vm._v("Tecnologias")]),_vm._v(" "),_c('aSelect',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          `technologies`,
          {
            rules: [
              {
                required: true,
                message: 'Selecione',
              },
            ],
          },
        ]),expression:"[\n          `technologies`,\n          {\n            rules: [\n              {\n                required: true,\n                message: 'Selecione',\n              },\n            ],\n          },\n        ]"}],staticClass:"travel-input",staticStyle:{"width":"100%"},attrs:{"placeholder":"Selecione","mode":"multiple","show-search":""}},_vm._l((_vm.technologiesList),function(item,index){return _c('a-select-option',{key:index,attrs:{"value":item.value}},[_vm._v("\n          "+_vm._s(item.value)+"\n        ")])}),1)],1)],1),_vm._v(" "),_c('aCol',{attrs:{"span":24}},[_c('a-form-item',{staticClass:"travel-input-outer"},[_c('label',{staticClass:"filled"},[_vm._v("Websites")]),_vm._v(" "),_c('aSelect',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          `websites`,
          {
            rules: [
              {
                required: true,
                message: 'Selecione',
              },
            ],
          },
        ]),expression:"[\n          `websites`,\n          {\n            rules: [\n              {\n                required: true,\n                message: 'Selecione',\n              },\n            ],\n          },\n        ]"}],staticClass:"travel-input",staticStyle:{"width":"100%"},attrs:{"placeholder":"Selecione","mode":"multiple","show-search":""}},_vm._l((_vm.websitesList),function(item,index){return _c('a-select-option',{key:index,attrs:{"value":item.value}},[_vm._v("\n          "+_vm._s(item.value)+"\n        ")])}),1)],1)],1),_vm._v(" "),_c('aCol',{attrs:{"span":12}},[_c('a-form-item',{staticClass:"travel-input-outer"},[_c('label',{staticClass:"filled"},[_vm._v("Status")]),_vm._v(" "),_c('aSelect',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          `status`,
          {
            rules: [
              {
                required: true,
                message: 'Selecione',
              },
            ],
          },
        ]),expression:"[\n          `status`,\n          {\n            rules: [\n              {\n                required: true,\n                message: 'Selecione',\n              },\n            ],\n          },\n        ]"}],staticClass:"travel-input",staticStyle:{"width":"100%"},attrs:{"placeholder":"Selecione","show-search":""}},_vm._l((_vm.statusList),function(item,index){return _c('a-select-option',{key:index,attrs:{"value":item.value}},[_vm._v("\n          "+_vm._s(item.value)+"\n        ")])}),1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }