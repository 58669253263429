var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"paragraphs"},[_c('aRow',[_c('draggable',{attrs:{"group":"paragraphs","list":_vm.paragraphs},on:{"end":_vm.endDrag}},_vm._l((_vm.paragraphs),function(p,i){return _c('aCol',{key:i,staticClass:"p",attrs:{"span":24}},[_c('div',{staticClass:"delete"},[_c('a-button',{attrs:{"type":"danger","shape":"circle","icon":"delete"},on:{"click":function($event){return _vm.onClickDeleteParagraph(p.id)}}})],1),_vm._v(" "),(
            ['text', 'info', 'warning', 'error', 'success'].includes(p.type)
          )?_c('DocumentationTextParagraph',{attrs:{"documentationId":_vm.documentationId,"paragraph":p},on:{"startsLoading":_vm.startsLoading,"savedSuccessfully":_vm.savedSuccessfully}}):_vm._e(),_vm._v(" "),(
            [
              'text/javascript',
              'text/html',
              'text/css',
              'text/php',
              'js',
              'php',
              'html',
            ].includes(p.type)
          )?_c('DocumentationCodeParagraph',{attrs:{"documentationId":_vm.documentationId,"paragraph":p},on:{"startsLoading":_vm.startsLoading,"savedSuccessfully":_vm.savedSuccessfully}}):_vm._e(),_vm._v(" "),(p.type === 'image')?_c('DocumentationImageParagraph',{attrs:{"documentationId":_vm.documentationId,"paragraph":p},on:{"startsLoading":_vm.startsLoading,"savedSuccessfully":_vm.savedSuccessfully}}):_vm._e(),_vm._v(" "),(p.type === 'file')?_c('DocumentationFileParagraph',{attrs:{"documentationId":_vm.documentationId,"paragraph":p},on:{"startsLoading":_vm.startsLoading,"savedSuccessfully":_vm.savedSuccessfully}}):_vm._e()],1)}),1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }