var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"pd-0"},[_c('aForm',{attrs:{"form":_vm.form},on:{"submit":function($event){$event.preventDefault();return _vm.onSubmitForm.apply(null, arguments)}}},[_c('aRow',[_c('aCol',{staticClass:"top-bar",attrs:{"span":24}},[_c('aRow',{attrs:{"type":"flex","justify":"space-between"}},[_c('aCol',[_c('aRow',{attrs:{"type":"flex","justify":"start","gutter":20}},[_c('aCol',[_c('a-icon',{staticClass:"menu",attrs:{"type":"menu"},on:{"click":function($event){_vm.openDocumentInfosDrawer = true}}})],1),_vm._v(" "),_c('aCol',[_c('a-form-item',{staticClass:"model-name"},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                      'title',
                      {
                        rules: [
                          {
                            required: true,
                            message: 'Obrigatório',
                          },
                        ],
                      },
                    ]),expression:"[\n                      'title',\n                      {\n                        rules: [\n                          {\n                            required: true,\n                            message: 'Obrigatório',\n                          },\n                        ],\n                      },\n                    ]"}],attrs:{"placeholder":"Escreva..."}})],1)],1),_vm._v(" "),_c('aCol',[_c('a-tooltip',{attrs:{"placement":"right","title":"Ver"}},[_c('a',{staticClass:"view",on:{"click":function($event){return _vm.$router.push(
                        `/documentations/view/${_vm.documentation.details.id}`
                      )}}},[_c('a-icon',{attrs:{"type":"eye"}})],1)])],1)],1)],1),_vm._v(" "),_c('aCol',{},[_c('a-button',{attrs:{"html-type":"submit","loading":_vm.loadingDocumentationForm,"size":"large","type":"primary"}},[_vm._v("Atualizar")])],1)],1)],1)],1),_vm._v(" "),_c('a-drawer',{attrs:{"title":"Dados da documentação","placement":"left","width":"600px","visible":_vm.openDocumentInfosDrawer},on:{"close":function($event){_vm.openDocumentInfosDrawer = false}}},[(_vm.openDocumentInfosDrawer)?_c('DocumentationExtraDataDrawer',{attrs:{"form":_vm.form,"tempDocumentation":_vm.tempDocumentation}}):_vm._e()],1)],1),_vm._v(" "),_c('aRow',[_c('aCol',{attrs:{"span":24}},[_c('div',{staticClass:"paragraphs-wrapper"},[_c('DocumentationParagraphsSection',{attrs:{"paragraphs":_vm.documentationParagraph.list,"documentationId":_vm.documentation.details.id},on:{"onClickDeleteParagraph":function($event){return _vm.getDocumentationParagraphs()},"startsLoading":function($event){_vm.loadingDocumentationForm = true},"afterUpdateAll":function($event){_vm.loadingDocumentationForm = false}}})],1)]),_vm._v(" "),_c('aCol',{staticClass:"add-paragraph",attrs:{"span":24}},[_c('aPopover',{attrs:{"title":false,"trigger":"click"},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('ul',{staticClass:"paragraphs-list"},_vm._l((_vm.paragraphTypes),function({ name, value, ico },i){return _c('li',{key:i,on:{"click":function($event){return _vm.onClickAddParagraph(value)}}},[_c('a-icon',{staticClass:"ico",attrs:{"type":ico}}),_vm._v(" "+_vm._s(name)+"\n            ")],1)}),0)]},proxy:true}]),model:{value:(_vm.openParagraphTypes),callback:function ($$v) {_vm.openParagraphTypes=$$v},expression:"openParagraphTypes"}},[_vm._v(" "),_c('a-button',[_vm._v("Adicionar Parágrafo")])],1)],1)],1),_vm._v(" "),(_vm.loadingDocumentationForm)?_c('div',{staticClass:"floating-loading"},[_c('a-icon',{attrs:{"type":"loading"}}),_vm._v(" salvando...\n  ")],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }