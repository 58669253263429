<template>
  <div>
    <aCol :span="24">
      <a-form-item class="travel-input-outer">
        <label class="filled">Tecnologias</label>
        <aSelect
          class="travel-input"
          placeholder="Selecione"
          mode="multiple"
          v-decorator="[
            `technologies`,
            {
              rules: [
                {
                  required: true,
                  message: 'Selecione',
                },
              ],
            },
          ]"
          show-search
          style="width: 100%"
        >
          <a-select-option
            v-for="(item, index) of technologiesList"
            :key="index"
            :value="item.value"
          >
            {{ item.value }}
          </a-select-option>
        </aSelect>
      </a-form-item>
    </aCol>

    <aCol :span="24">
      <a-form-item class="travel-input-outer">
        <label class="filled">Websites</label>
        <aSelect
          class="travel-input"
          placeholder="Selecione"
          mode="multiple"
          v-decorator="[
            `websites`,
            {
              rules: [
                {
                  required: true,
                  message: 'Selecione',
                },
              ],
            },
          ]"
          show-search
          style="width: 100%"
        >
          <a-select-option
            v-for="(item, index) of websitesList"
            :key="index"
            :value="item.value"
          >
            {{ item.value }}
          </a-select-option>
        </aSelect>
      </a-form-item>
    </aCol>

    <aCol :span="12">
      <a-form-item class="travel-input-outer">
        <label class="filled">Status</label>
        <aSelect
          class="travel-input"
          placeholder="Selecione"
          v-decorator="[
            `status`,
            {
              rules: [
                {
                  required: true,
                  message: 'Selecione',
                },
              ],
            },
          ]"
          show-search
          style="width: 100%"
        >
          <a-select-option
            v-for="(item, index) of statusList"
            :key="index"
            :value="item.value"
          >
            {{ item.value }}
          </a-select-option>
        </aSelect>
      </a-form-item>
    </aCol>
  </div>
</template>

<script>
export default {
  name: "DocumentationExtraDataDrawer",
  props: {
    form: Object,
    tempDocumentation: Object,
  },
  data() {
    return {
      technologiesList: [],
      websitesList: [],
      statusList: [
        {
          label: "Em edição",
          value: "Em edição",
        },
        {
          label: "Finalizado",
          value: "Finalizado",
        },
        {
          label: "Revisão",
          value: "Revisão",
        },
      ],
    };
  },
  mounted() {
    this.$http
      .get(
        `/category-entry/list?page=1&per_page=500&category_id=35&order-by=name&order=ascend`
      )
      .then(({ data }) => {
        this.technologiesList = data.data.map(({ name }) => {
          return {
            label: `${name}`,
            value: name,
          };
        });
      });

    this.$http
      .get(
        `/category-entry/list?page=1&per_page=500&category_id=34&order-by=name&order=ascend`
      )
      .then(({ data }) => {
        this.websitesList = data.data.map(({ name }) => {
          return {
            label: `${name}`,
            value: name,
          };
        });
      });

    this.form.setFieldsValue({
      technologies: this.tempDocumentation.technologies
        ? JSON.parse(this.tempDocumentation.technologies)
        : [],
      websites: this.tempDocumentation.websites
        ? JSON.parse(this.tempDocumentation.websites)
        : [],
      status: this.tempDocumentation.status,
    });
  },
};
</script>
