<template>
  <section class="paragraphs">
    <aRow>
      <draggable group="paragraphs" :list="paragraphs" @end="endDrag">
        <aCol class="p" v-for="(p, i) in paragraphs" :key="i" :span="24">
          <div class="delete">
            <a-button
              @click="onClickDeleteParagraph(p.id)"
              type="danger"
              shape="circle"
              icon="delete"
            />
          </div>

          <DocumentationTextParagraph
            v-if="
              ['text', 'info', 'warning', 'error', 'success'].includes(p.type)
            "
            :documentationId="documentationId"
            :paragraph="p"
            @startsLoading="startsLoading"
            @savedSuccessfully="savedSuccessfully"
          />

          <DocumentationCodeParagraph
            v-if="
              [
                'text/javascript',
                'text/html',
                'text/css',
                'text/php',
                'js',
                'php',
                'html',
              ].includes(p.type)
            "
            :documentationId="documentationId"
            :paragraph="p"
            @startsLoading="startsLoading"
            @savedSuccessfully="savedSuccessfully"
          />

          <DocumentationImageParagraph
            v-if="p.type === 'image'"
            :documentationId="documentationId"
            :paragraph="p"
            @startsLoading="startsLoading"
            @savedSuccessfully="savedSuccessfully"
          />

          <DocumentationFileParagraph
            v-if="p.type === 'file'"
            :documentationId="documentationId"
            :paragraph="p"
            @startsLoading="startsLoading"
            @savedSuccessfully="savedSuccessfully"
          />
        </aCol>
      </draggable>
    </aRow>
  </section>
</template>

<script>
import draggable from "vuedraggable";

import DocumentationCodeParagraph from "../paragraphs/DocumentationCodeParagraph.vue";
import DocumentationImageParagraph from "../paragraphs/DocumentationImageParagraph.vue";
import DocumentationFileParagraph from "../paragraphs/DocumentationFileParagraph.vue";
import DocumentationTextParagraph from "../paragraphs/DocumentationTextParagraph.vue";

export default {
  name: "DocumentationParagraphsSection",
  components: {
    draggable,
    DocumentationTextParagraph,
    DocumentationCodeParagraph,
    DocumentationImageParagraph,
    DocumentationFileParagraph,
  },
  props: {
    paragraphs: Array,
    documentationId: String,
  },
  data() {
    return {};
  },
  mounted() {},
  methods: {
    onClickDeleteParagraph(id) {
      this.$http.post(`/documentation-paragraph/delete?id=${id}`).then(() => {
        this.$emit("onClickDeleteParagraph");
      });
    },
    async updateEachParagraph(id, order) {
      await this.$http
        .post("/documentation-paragraph/update-field", {
          id,
          field: "order",
          value: order,
        })
        .then(() => {
          // this.$http.post("/log/create", {
          //   user_id: this.$store.state.userData.id,
          //   module_id: opt.clone.dataset.id,
          //   module: "opportunity",
          //   action: "change-opportunity-funnel-step",
          //   description: `${this.$store.state.userData.first_name} ${this.$store.state.userData.last_name} passou a oportunidade da etapa <b>${opt.from.dataset.step}</b> para a etapa <b>${opt.to.dataset.step}</b>.`,
          // });
        });
    },
    savedSuccessfully() {
      setTimeout(() => {
        this.$emit("afterUpdateAll");
      }, 200);
    },
    startsLoading() {
      this.$emit("startsLoading");
    },
    async afterUpdateAll() {
      this.$emit("afterUpdateAll");
    },
    async endDrag() {
      let order = 0;
      this.$emit("startsLoading");
      for (const paragraph of this.paragraphs) {
        try {
          await this.updateEachParagraph(paragraph.id, order);
        } catch (e) {
          console.log(e);
        }
        order++;
      }

      await this.afterUpdateAll();
    },
  },
};
</script>

<style lang="sass" scoped>
.paragraphs
  .p
    background: #fff
    margin-bottom: 20px
    display: block
    box-shadow: 0 0 40px #ddd
    padding: 10px
    border-radius: 6px
    min-height: 60px
    .delete
      position: absolute
      right: -40px
      width: 50px
      display: none
      transition: .3s
      text-align: right
    &:hover
      .delete
        display: block
</style>
