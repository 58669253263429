export default {
  data() {
    return {
      documentationParagraph: {
        loading: false,
        details: {},
        list: [],
        meta: {},
        filters: {
          id: "",
          documentationId: "",
          users: {
            selected: [],
          },
          companies: {
            list: [],
            selected: [],
          },
          companyBranches: {
            list: [],
            selected: [],
          },
          period: {
            selected: [],
          },
          order: "ascend",
          orderBy: "created",
        },
        pagination: {
          page: 1,
          perPage: 9999,
          total: 0,
          complete_list_total: 0,
          totalPages: 0,
        },
      },
    };
  },
  methods: {
    changeDocumentationParagraphPage(current) {
      this.documentationParagraph.pagination.page = current;
      this.getDocumentationParagraphs();
    },
    documentationParagraphTableChange(pagination, filters, sorter) {
      filters;
      pagination;
      console.log(filters, pagination);
      this.documentationParagraph.filters.order =
        sorter.order != undefined ? sorter.order : "desc";
      this.documentationParagraph.filters.orderBy =
        sorter.column != undefined ? sorter.column.key : "created";
      this.getDocumentationParagraphs();
    },
    documentationParagraphChangePageSize(current, pageSize) {
      this.documentationParagraph.pagination.page = current;
      this.documentationParagraph.pagination.perPage = pageSize;
      this.getDocumentationParagraphs();
    },
    documentationParagraphFilters() {
      let filters = "";

      if (this.documentationParagraph.filters.documentationId) {
        filters += `&documentation_id=${this.documentationParagraph.filters.documentationId}`;
      }

      if (this.documentationParagraph.filters.id) {
        filters += `&id=${this.documentationParagraph.filters.id}`;
      }

      // if (this.documentationParagraph.filters.companies.selected.length > 0) {
      //   filters += `&company_id=${this.documentationParagraph.filters.companies.selected}`;
      // }

      // if (
      //   this.documentationParagraph.filters.companyBranches.selected.length > 0
      // ) {
      //   filters += `&company_branch_id=${this.documentationParagraph.filters.companyBranches.selected}`;
      // }

      if (this.documentationParagraph.filters.period.selected.length > 0) {
        filters += `&period=${this.documentationParagraph.filters.period.selected[0]}|${this.documentationParagraph.filters.period.selected[1]}`;
      }

      filters += `&order=${this.documentationParagraph.filters.order}&order-by=${this.documentationParagraph.filters.orderBy}`;

      return filters;
    },
    async getDocumentationParagraphById(id) {
      try {
        const data = await this.$http.get(
          `/documentation-paragraph/details?id=${id}`
        );
        this.documentationParagraph.details = data.data;
        return data;
      } catch (error) {
        console.log(error);
        this.$message.error("Houve algum erro ao carregar os dados.");
        this.documentationParagraph.details = {};
      }
    },
    async getDocumentationParagraphs(samePage) {
      this.documentationParagraph.loading = true;
      try {
        const { data } = await this.$http.get(
          `/documentation-paragraph/list?page=${
            this.documentationParagraph.pagination.page
          }&per_page=${
            this.documentationParagraph.pagination.perPage
          }${this.documentationParagraphFilters()}`
        );

        this.documentationParagraph.list = data.data;
        this.documentationParagraph.meta = data.meta;
        this.documentationParagraph.pagination.page = data.meta.next_page;
        this.documentationParagraph.pagination.total = data.meta.total;

        if (!samePage) {
          this.documentationParagraph.pagination.page = 1;
        }
      } catch (e) {
        console.log(e);
        this.documentationParagraph.list = [];
        this.documentationParagraph.meta = {
          total: 0,
          total_available: "0,00",
          total_general: 0,
          total_pages: 0,
          total_spent: "0,00",
          total_value: "0,00",
        };
      } finally {
        this.documentationParagraph.loading = false;
      }
    },
  },
};
